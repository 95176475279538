<template>
  <div class="detail">
    <HeaderTitle :title="item.title" :showLeft="true"></HeaderTitle>
    <a-skeleton class="skeleton" v-if="loading" active />
    <div v-else>
      <Phone v-if="ua === 'phone'" :item="item" @submit="handleSubmit"></Phone>
      <Web v-else :item="item" @submit="handleSubmit"></Web>
    </div>
  </div>
</template>

<script>
import HeaderTitle from '@/components/HeaderTitle';
import Phone from './item/Phone';
import Web from './item/Web';
import { mapGetters } from 'vuex';
export default {
  name: 'Detail',
  components: { Phone, Web, HeaderTitle },
  props: {},
  data() {
    return {
      item: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters(['ua'])
  },
  created() {
    this.id = this.$route.params.id;
    this.params = {
      name: this.id
    };
    this.getData();
  },
  mounted() {
    var u = navigator.appVersion;
    console.log(u, 'uuu');
  },
  watch: {},
  methods: {
    async handleSubmit(form) {
      let params = {
        payment_link: this.item.payment_link,
        payer_email: form.email,
        payer_name: form.name,
        payer_wechat: form.wechat,
        payer_address: form.hometown,
        payer_phone: form.phone,
        payer_school: form.school,
        payer_grade: form.grade,
        payer_gpa: form.gpa,
        payer_major: form.profession,
        note: form.note
      };
      let res = await this.$api['home/pay'](params);
      if (res.status.code === 200 && res.data.display.pay_link) {
        this.openBrowser(res.data.display.pay_link);
      }
      console.log(res, 'ress');
    },
    openBrowser(url) {
      window.location.href = url;
      // var u = navigator.appVersion
      // console.log(u, 'uuu')
      // var isIosUc = u.includes('iPhone') && u.includes('UCBrowser')
      // var isWeixin = u.includes('MicroMessenger')
      // if (isIosUc || isWeixin) {
      //   window.location.href = url
      // } else {
      //   this.newTab = window.open()
      //   setTimeout(() => {
      //     this.newTab.location.href = url
      //   })
      // }
    },
    async getData() {
      console.log(this, 'this');
      this.loading = true;
      let res = await this.$api['home/detail'](this.params);
      this.loading = false;
      if (res.status.code === 200) {
        this.item = res.data.display;
      }
    }
  }
};
</script>

<style scoped lang="less">
.detail {
  .swiper-wrap {
    img {
      width: 100%;
      height: 230px;
    }
  }
  .top-wrap {
    padding: 10px 15px;
    h3 {
      text-align: left;
    }
    p {
      text-align: left;
      margin-top: 10px;
    }
    .tag-wrap {
      margin-top: 10px;
      text-align: left;
      font-size: 13px;
      display: flex;
      align-items: center;
      .van-icon {
        margin-right: 5px;
      }
      span {
        margin-right: 12px;
      }
    }
  }
  .skeleton {
    margin-top: 46px;
    padding: 0 15px;
  }
}
</style>
