<template>
  <div class="detail-phone" ref="detail">
    <div class="swiper-wrap">
      <img class="my-swipe" :src="imgList[0]" alt="" />
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(imgItem, imgIndex) in imgList" :key="imgIndex"><img :src="imgItem" alt="" /></van-swipe-item>
      </van-swipe> -->
    </div>
    <div class="top-wrap">
      <h3>{{ item.title }}</h3>
      <p>{{ item.desc }}</p>
      <div class="tag-wrap" v-if="item.tags">
        <van-icon name="eye-o" />
        <span>{{ item.viewNum }} </span>
        <van-icon name="eye-o" />
        <span>{{ item.payNum }} </span>
        <van-icon name="eye-o" />
        <span>{{ item.orderNum }}</span>
      </div>
    </div>
    <div class="desc">
      <p v-html="richText"></p>
    </div>
    <van-submit-bar currency="$" :price="item.price * 100" button-text="提交订单" @submit="showDialog" />
    <van-popup class="popup-wrap" v-model="visible" closeable position="bottom" :get-container="() => $refs.detail">
      <div>
        <div class="title">填写信息</div>
        <van-form @submit="onSubmit">
          <van-field
            required
            v-model="form.name"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名!' }]"
          />
          <van-field
            required
            v-model="form.email"
            name="邮箱"
            label="邮箱"
            placeholder="请输入邮箱"
            :rules="[
              { required: true, message: '请输入邮箱!' },
              {
                pattern: /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/i,
                message: '邮箱格式不正确!'
              }
            ]"
          />
          <van-field
            required
            v-model="form.wechat"
            name="微信"
            label="微信"
            placeholder="请输入微信"
            :rules="[{ required: true, message: '请输入微信帐号!' }]"
          />
          <van-field v-model="form.hometown" name="籍贯" label="籍贯" placeholder="请输入籍贯" />
          <van-field v-model="form.phone" name="联系电话" label="联系电话" placeholder="请输入联系电话" />
          <van-field v-model="form.school" name="学校" label="学校" placeholder="请输入学校" />
          <van-field v-model="form.grade" name="年级" label="年级" placeholder="请输入年级" />
          <van-field v-model="form.profession" name="专业" label="专业" placeholder="请输入专业" />
          <van-field v-model="form.gpa" name="GPA" label="GPA" placeholder="请输入GPA" />
          <div class="btn-wrap">
            <van-button
              :loading="submit.loading"
              :loading-text="submit.title"
              type="primary"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              native-type="submit"
              block
              >{{ submit.title }}</van-button
            >
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      default: function () {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      visible: false,
      submit: {
        loading: false,
        title: '确定'
      },

      form: {
        name: '',
        email: '',
        wechat: '',
        hometown: '',
        phone: '',
        school: '',
        grade: '',
        profession: '',
        gpa: ''
      }
    };
  },
  computed: {
    imgList() {
      return [1, 2, 3, 4, 5].map(() => {
        return this.item.thumb_url;
      });
    },
    richText() {
      if (!this.item.rich_desc) {
        return '';
      }
      let text = this.item.rich_desc
        .replace(/!important/gi, '')
        .replace(/height:[^"]+;/gi, '')
        .replace(/width:[^"]+;/gi, '')
        .replace(/style=""/gi, '')
        .replace(/<img([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<img$1')
        .replace(/<a([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<a$1')
        .replace(/<table([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<table$1')
        .replace(/line-height:[^"]+;/gi, '')
        .replace(/<img([\u4e00-\u9fa5\s\w"-=/.:;]+)/gi, '<img$1 style="width: 100%!important;"')
        .replace(/<a([\u4e00-\u9fa5\s\w"-=/.:;）]+)/gi, '<a$1 style="word-break:break-all;"')
        .replace(/<table([\u4e00-\u9fa5\s\w"-=/.:;]+)/gi, '<table$1 style="width: 100%;"')
        .replace(/<p><\/p>/gi, '')
        .trim();

      console.log(text, 'textphone');
      return text;
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    showDialog() {
      this.track({ event: 'click_shop_order', des: '触发立即购买，弹出信息弹框' });
      this.visible = true;
    },

    async onSubmit() {
      this.submit.loading = true;
      this.submit.title = '正在生成订单链接，请稍后...';
      await this.$parent.handleSubmit(this.form);
      this.track({ event: 'click_shop_pay', des: '支付成功' });
      // this.$emit('submit', this.form)
      this.submit.loading = false;
      this.submit.title = '确定';
      this.visible = false;
      // this.$toast('触发了购买事件')
    },
    onClickLeft() {
      this.$router.back();
    },
    track(item) {
      this.$gtag.event(item.event, {
        event_category: 'pay',
        event_label: item.des
      });
    }
  }
};
</script>

<style scoped lang="less">
.detail-phone {
  margin-top: 46px;

  .swiper-wrap {
    img {
      width: 100%;
      height: 230px;
    }
  }
  .top-wrap {
    padding: 10px 15px;
    h3 {
      text-align: left;
    }
    p {
      text-align: left;
      margin-top: 10px;
    }
    .tag-wrap {
      margin-top: 10px;
      text-align: left;
      font-size: 13px;
      display: flex;
      align-items: center;
      .van-icon {
        margin-right: 5px;
      }
      span {
        margin-right: 12px;
      }
    }
  }
  .popup-wrap {
    padding-bottom: 30px;
    .title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      padding: 15px 0;
      text-align: center;
    }
    .btn-wrap {
      padding: 0 40px;
    }
    .van-button {
      margin-top: 15px;
    }
  }
  .desc {
    text-align: left;
    padding: 0 15px;
    line-height: 24px;
    /deep/ span {
      font-size: 14px !important;
      line-height: 24px;
    }
    /deep/ li {
      font-size: 14px !important;
    }
    img {
      width: 100% !important;
    }
  }
}
</style>
