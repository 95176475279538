<template>
  <div class="detail-web" ref="detail">
    <div class="main">
      <div class="swiper-wrap">
        <img :src="imgList[0]" />
        <!-- <a-carousel arrows dots-class="slick-dots slick-thumb">
          <a slot="customPaging" slot-scope="props">
            <img :src="imgList[props.i]" />
          </a>
          <div v-for="(item, index) in imgList" :key="index">
            <img :src="item" />
          </div>
        </a-carousel> -->
      </div>
      <div class="top-wrap">
        <h3>{{ item.title }}</h3>
        <div class="price-wrap">
          <span class="price-label">价格：</span>
          <div class="price-con">
            <span>$</span><span class="price">{{ item.price }}</span>
          </div>
        </div>
        <p>{{ item.desc }}</p>
        <div class="tag-wrap" v-if="item.tags">
          <van-icon name="eye-o" />
          <span>{{ item.viewNum }} </span>
          <van-icon name="eye-o" />
          <span>{{ item.payNum }} </span>
          <van-icon name="eye-o" />
          <span>{{ item.orderNum }}</span>
        </div>
        <div class="btn-wrap">
          <a-button size="large" @click="onSubmit">立即购买</a-button>
        </div>
      </div>
    </div>
    <div class="desc">
      <p v-html="richText"></p>
    </div>
    <a-modal
      :getContainer="() => $refs.detail"
      title="填写信息"
      width="900px"
      :visible="visible"
      :confirm-loading="submit.loading"
      :okText="submit.title"
      :okButtonProps="{ props: { size: 'large' }, on: {} }"
      :cancelButtonProps="{ props: { size: 'large' }, on: {} }"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form layout="inline" :form="form" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="姓名">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入姓名!' }] }]" />
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  { required: true, message: '请输入邮箱!' },
                  {
                    pattern: /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/i,
                    message: '邮箱格式不正确!'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="微信">
          <a-input v-decorator="['wechat', { rules: [{ required: true, message: '请输入微信帐号!' }] }]" />
        </a-form-item>
        <a-form-item label="籍贯">
          <a-input v-decorator="['hometown']" />
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input v-decorator="['phone']" />
        </a-form-item>
        <a-form-item label="学校">
          <a-input v-decorator="['school']" />
        </a-form-item>
        <a-form-item label="年级">
          <a-input v-decorator="['grade']" />
        </a-form-item>
        <a-form-item label="专业">
          <a-input v-decorator="['profession']" />
        </a-form-item>
        <a-form-item label="GPA">
          <a-input v-decorator="['gpa']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      default: function () {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      submit: {
        loading: false,
        title: '确定'
      }
    };
  },
  computed: {
    imgList() {
      return [1, 2, 3, 4, 5].map(() => {
        return this.item.thumb_url;
      });
    },
    richText() {
      if (!this.item.rich_desc) {
        return '';
      }
      let text = this.item.rich_desc
        .replace(/!important/gi, '')
        .replace(/height:[^"]+;/gi, '')
        .replace(/width:[^"]+;/gi, '')
        .replace(/style=""/gi, '')
        .replace(/<img([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<img$1')
        .replace(/<a([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<a$1')
        .replace(/<table([\u4e00-\u9fa5\s\w"-=/.:;]+)((?:(style="[^"]+")))/gi, '<table$1')
        .replace(/line-height:[^"]+;/gi, '')
        // .replace(/<img([\u4e00-\u9fa5\s\w"-=/.:;]+)/gi, '<img$1 style="width: 50%;"')
        .replace(/<a([\u4e00-\u9fa5\s\w"-=/.:;）]+)/gi, '<a$1 style="word-break:break-all;"')
        .replace(/<table([\u4e00-\u9fa5\s\w"-=/.:;]+)/gi, '<table$1 style="width: 100%;"')
        .replace(/<p><\/p>/gi, '')
        .trim();
      console.log(text, 'textweb');
      return text;
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    getImgUrl(item) {
      console.log(item);
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          this.submit.loading = true;
          this.submit.title = '正在生成订单链接，请稍后...';
          // console.log(this.form)
          await this.$parent.handleSubmit(values);

          this.track({ event: 'click_shop_pay', des: '支付成功' });
          this.submit.loading = false;
          this.submit.title = '确定';

          this.visible = false;
        }
      });
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.visible = false;
    },
    onSubmit() {
      this.track({ event: 'click_shop_order', des: '触发立即购买，弹出信息弹框' });
      this.visible = true;
      // this.$toast('触发了购买事件')
    },
    track(item) {
      this.$gtag.event(item.event, {
        event_category: 'pay',
        event_label: item.des
      });
    }
  }
};
</script>

<style scoped lang="less">
.detail-web {
  width: 1000px;
  margin: 0 auto;
  margin-top: 46px;
  /deep/ .ant-page-header-back-button {
    &:hover {
      color: #ff6034;
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .swiper-wrap {
    // padding-bottom: 45px;
    width: 40%;
    img {
      max-width: 80%;
      min-height: 200px;
      max-height: 300px;
    }
    /* For demo */
    /deep/ .ant-carousel {
      .slick-slide img {
        border: 5px solid #fff;
        display: block;
        margin: auto;
        max-width: 80%;
        min-height: 200px;
        max-height: 300px;
      }
      .slick-dots {
        height: auto;
      }
      .slick-thumb {
        bottom: -45px;
        li {
          width: 60px;
          height: 45px;
          img {
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
          }
        }
        li.slick-active img {
          filter: grayscale(0%);
        }
      }
    }
  }
  .top-wrap {
    padding: 10px 15px;
    flex: 1;
    h3 {
      text-align: left;
      font-size: 20px;
    }
    .price-wrap {
      margin-top: 20px;
      height: 40px;
      display: flex;
      flex-direction: row;
      line-height: 40px;
      text-align: left;
      font-size: 14px;

      .price-label {
      }
      .price-con {
        margin-left: 15px;
        background: #e0e0e0;
        flex: 1;
        span {
          margin-left: 10px;
        }
        .price {
          font-size: 20px;
          margin-left: 2px;
          color: #ff6034;
          font-weight: bold;
        }
      }
    }
    p {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
    }
    .tag-wrap {
      margin-top: 10px;
      text-align: left;
      font-size: 14px;
      display: flex;
      align-items: center;
      .van-icon {
        margin-right: 5px;
      }
      span {
        margin-right: 12px;
      }
    }
    .btn-wrap {
      margin-top: 20px;
      .ant-btn {
        background: #ff6034;
        color: #fff;
        width: 120px;
        &:hover {
          border-color: #ff6034;
        }
        &:focus {
          border-color: #ff6034;
        }
      }
    }
  }
  .ant-form {
    text-align: left;
    /deep/ .ant-form-item {
      width: 400px;
    }
  }
  .desc {
    text-align: left;
    padding: 40px;
    line-height: 32px;
    /deep/ span {
      font-size: 14px !important;
      line-height: 32px;
    }
    /deep/ li {
      font-size: 14px !important;
    }
  }
}
</style>
